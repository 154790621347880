import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ankur from "../../assets/images/ankur-agrawal-2025.jpg";
import img from "../../assets/images/who-we-are.png";
import HowWeStarted from "./HowWeStarted";
import Info from "./Info";

const AboutPage = () => {
  useEffect(() => {
    document.title = "Aakash Group | About";
  }, []);

  return (
    <div className="about-page page">
      <div className="page-header">
        <div className="text">
          <h1>About Us</h1>
          <p>
            Thousands of people and business all over Nepal use Aakash Group.
          </p>
        </div>
      </div>
      <HowWeStarted />
      <div className="side-by-side component">
        <div className="container">
          <div className="left">
            <img src={img} alt="" />
          </div>
          <div className="right">
            <div className="title">
              <div className="main">Who We Are</div>
            </div>
            <p className="desc">
              Aakash Group is located in the heart of Nepal, Kathmandu, where we
              form an intrinsic part of every realm of life from{" "}
              <Link to="/industries">packaged food manufacturing</Link>,{" "}
              <Link to="/industries">restaurants</Link>,{" "}
              <Link to="/industries">insurance</Link>,{" "}
              <Link to="/industries">real estate</Link> and{" "}
              <Link to="/industries">banking</Link>. Aakash Group continues to
              provide cost-effective solutions to businesses all over Nepal. The
              company prides itself in its premium quality and exceptional
              customer service.
            </p>
          </div>
        </div>
      </div>
      <div className="how-we-started component">
        <div className="container">
          <div className="left">
            <div className="title">
              <div className="main">About the Managing Director </div>
            </div>
            <div className="content">
              <p>
                Mr. Ankur Agrawal is the Managing Director of Aakash Group,
                leading multiple companies across diverse industries. He is the
                Founder and CEO of Aakash Labs, a firm specializing in software
                development and digital media management. He also founded and
                currently chairs Aakash Capital Ltd., a licensed Merchant Bank
                regulated by the Securities Board of Nepal (SEBON).
                Additionally, he serves as the Managing Proprietor of Aakash
                Foods, which focuses on manufacturing packaged food products and
                operating a fast food restaurant chain.{" "}
              </p>
              <p>
                Ankur successfully founded, built, and exited Aakash Tech, which
                developed an innovative aggregator app and Bulk SMS solutions.
                He has served as a board member of CNIYEF, member of CNI, Round
                Table and is an alumnus of the WEF Global Shapers community.
              </p>
              <p>
                He holds a Bachelor of Science in Finance from Bentley
                University, Boston, and completed MBA from the McCallum Graduate
                School of Business.
              </p>
            </div>
          </div>
          <div className="right">
            <img src={ankur} alt="" width={606} height={341} />
          </div>
        </div>
      </div>
      <Info />
    </div>
  );
};

export default AboutPage;
