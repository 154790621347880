import React from "react";
import CareerDetailPage from "./CareerDetailPage";

const careerDetails = {
  title: "Junior Accountant",
  type: "Full Time",
  level: "Mid Level",

  description:
    "We are seeking a detail-oriented Junior Accountant to join our finance team. The ideal candidate will assist in maintaining financial records, preparing reports, and ensuring accuracy in accounting processes. This role is ideal for someone with basic accounting knowledge, strong attention to detail, and a willingness to learn.",

  responsibilities: [
    "Assist with day-to-day accounting tasks, including accounts payable and receivable.",
    "Maintain financial records, ledgers, and journals.",
    "Prepare invoices, process payments, and reconcile bank statements.",
    "Work closely with senior accountants and finance managers.",
    "Perform other accounting-related tasks as assigned.",
  ],

  qualifications: [
    "Fluent understanding of accounting principles and financial regulations.",
    "Proficiency in data entry, typing skills, and MS Excel.",
    "Experience with accounting software such as Swastik, IMS, or Tally.",
    "Currently pursuing a Bachelor's degree in Business Studies or Management",
    "One year of experience is preferred, but freshers can also apply.",
    "Must own a two-wheeler and have a valid driving license.",
  ],

  noOfOpenings: 1,
};

const JuniorAccountant = () => {
  return <CareerDetailPage careerDetails={careerDetails} />;
};

export default JuniorAccountant;
